<template>
  <div v-if="!locationsLoaded" class="text-sm">
    <IconComponent name="PhSpinner" class="rotation" />
  </div>
  <BreadcrumbComponent v-else>
    <BreadcrumbList>
      <BreadcrumbItem>
        <DropdownMenu
          v-if="
            contextStore.locationCount > 1 || (!currentLocation && contextStore.locationCount > 0)
          "
        >
          <DropdownMenuTrigger class="flex items-center gap-1">
            {{ currentLocation || 'select location' }}
            <IconComponent name="PhCaretDown" size="md" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="start">
            <template v-for="location in contextStore.getLocations" :key="location.reference">
              <DropdownMenuItem
                v-if="location.reference === contextStore.getLocation?.reference"
                disabled
              >
                <IconComponent name="PhCheck" class="mr-2" size="md" />
                {{ location.label }}
              </DropdownMenuItem>
              <DropdownMenuItem
                v-if="location.reference !== contextStore.getLocation?.reference"
                @click="handleLocationChange(location.reference)"
              >
                {{ location.label }}
              </DropdownMenuItem>
            </template>
          </DropdownMenuContent>
        </DropdownMenu>
        <span v-if="currentLocation && contextStore.locationCount === 1">
          {{ currentLocation }}
        </span>
        <span
          v-if="
            !currentLocation && contextStore.locationCount === 0 && !isLoading && locationsLoaded
          "
        >
          <RouterLink to="/location/create" class="btn btn-light btn-small btn-sm mr-1">
            Add location
          </RouterLink>
        </span>
      </BreadcrumbItem>
      <template v-if="breadCrumbParents && breadCrumbParents?.length < 3">
        <template v-for="parent in breadCrumbParents" :key="parent.path">
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <RouterLink v-if="parent.path" :to="parent.path">{{ parent.title }}</RouterLink>
            <span v-else>{{ parent.title }}</span>
          </BreadcrumbItem>
        </template>
      </template>
      <template v-else>
        <BreadcrumbSeparator />
        <BreadcrumbItem>...</BreadcrumbItem>
      </template>
      <BreadcrumbSeparator />
      <BreadcrumbItem>{{ route.meta.title || route.name }}</BreadcrumbItem>
    </BreadcrumbList>
  </BreadcrumbComponent>
</template>

<script setup lang="ts">
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import BreadcrumbComponent from '@/components/ui/breadcrumb/BreadcrumbComponent.vue';
import BreadcrumbItem from '@/components/ui/breadcrumb/BreadcrumbItem.vue';
import BreadcrumbList from '@/components/ui/breadcrumb/BreadcrumbList.vue';
import BreadcrumbSeparator from '@/components/ui/breadcrumb/BreadcrumbSeparator.vue';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useContextStore } from '@/store';

const { isLoading, locationsLoaded } = storeToRefs(useContextStore());
const contextStore = useContextStore();

const currentLocation = computed(() => contextStore.getLocation?.label);

const route = useRoute();

const handleLocationChange = (location: string) => {
  contextStore.setLocation(location);
  window.location.reload();
};

type RouteInfo = {
  title: string;
  path: string;
};

const breadCrumbParents = computed<RouteInfo[] | null>(() => {
  // Split the path and remove any leading empty segment caused by the leading '/'
  const relevantSegments = route.path.split('/').filter(Boolean).slice(0, -1);

  // Map the relevant segments to the breadcrumb information
  return relevantSegments
    .map((segment) => {
      switch (segment) {
        case 'shipments':
          return { title: 'Shipments', path: '/shipments/' };
        case 'locations':
          return { title: 'Locations', path: '/locations/' };
        case 'address':
          return { title: 'Address', path: '/address/' };
        case 'configuration':
          return { title: 'Configuration' };
        case 'user':
          return { title: 'User', path: '/user/management' };
        case 'management':
          return { title: 'Management' };
        default:
          return null;
      }
    })
    .filter((info): info is RouteInfo => info !== null);
});
</script>
