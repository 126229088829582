import { type RouteRecordRaw } from 'vue-router';

const Configurations: Array<RouteRecordRaw> = [
  {
    path: '/configuration/',
    name: 'ConfigurationOverview',
    component: () => import('../pages/configuration/ConfigurationOverviewPage.vue'),
    meta: {
      title: 'Configuration Overview',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/references',
    name: 'ReferenceGeneratorPage',
    component: () => import('../pages/configuration/ReferenceGenerator.vue'),
    meta: {
      title: 'Reference Generator Overview',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/webhooks',
    name: 'WebhooksConfigurationPage',
    component: () => import('../pages/configuration/WebhooksPage.vue'),
    meta: {
      title: 'Webhook Overview',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/shipments',
    name: 'ShipmentsConfigurationPage',
    component: () => import('../pages/configuration/CreateShipment.vue'),
    meta: {
      title: 'Configure Shipments',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/rates',
    name: 'RatesContract',
    component: () => import('../pages/rates/RatesContracts.vue'),
    meta: {
      title: 'Rates Configuration',
    },
  },
  {
    path: '/configuration/devmode',
    name: 'ViewElementsSetting',
    component: () => import('../pages/configuration/ViewDevMode.vue'),
    meta: {
      title: 'View elements',
    },
  },
];

export default Configurations;
