import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

const InternalRoutes: Array<RouteRecordRaw> = [
  // Internal Pages and Design System
  {
    path: '/internal',
    name: 'Internal',
    component: () => import('../pages/internal/InternalIndex.vue'),
    props: true,
    meta: {
      title: 'Internal',
      access: {
        url: 'internal',
        method: 'GET',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: '/internal/json-forms',
    name: 'JSON-Forms-Example',
    component: () => import('../pages/internal/JsonExampleVue.vue'),
    props: true,
    meta: {
      title: 'JSONForms example',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/internal/json-forms-preview',
    name: 'JSON-Forms-Preview',
    component: () => import('../pages/internal/FormPreview.vue'),
    meta: {
      title: 'JSONForms preview',
    },
  },
  {
    path: '/internal/opa',
    name: 'OpaStatus',
    component: () => import('../pages/internal/OPAOverview.vue'),
    props: true,
    meta: {
      title: 'OPA overview',
    },
  },
];

export default InternalRoutes;
