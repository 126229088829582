import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';
import { useCarrierProfileStore } from '@/store/carrier-profile';

const CarrierConfiguration: Array<RouteRecordRaw> = [
  // Carrier Profile configuration
  {
    path: '/carrier-profile/:location/create',
    name: 'PageCreateCarrierProfile',
    component: () => import('../pages/configuration/CreateCarrierProfile.vue'),
    meta: {
      title: 'Create Carrier Profile',
      bodyClass: 'legacy-form',
      access: {
        url: 'locations/:location/carrierProfiles',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },

  {
    path: '/configuration/carrier',
    name: 'ConfigurationCarrier',
    component: () => import('../pages/configuration/LocationSelection.vue'),
    meta: {
      title: 'Carrier Profile Configuration',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/carrier/:location',
    name: 'ConfigurationCarrierLocation',
    props: true,
    component: () => import('../pages/configuration/CarrierProfiles.vue'),
    meta: {
      title: 'Carrier Profile Configuration',
      bodyClass: 'legacy-form',
    },
    beforeEnter: (to, from, next) => {
      const store = useCarrierProfileStore();
      store.$reset();
      next();
    },
  },
  {
    path: '/configuration/carrier/:location/:carrierProfileReference',
    name: 'ConfigurationCarrierProfile',
    props: true,
    component: () => import('../pages/configuration/CreateCarrierProfile.vue'),
    meta: {
      title: 'Carrier Profile Configuration',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/carrier/:location/:carrierProfileReference/onboarding',
    name: 'ConfigurationCarrierProfileOnboarding',
    props: true,
    component: () => import('../pages/configuration/CarrierOnboarding.vue'),
    meta: {
      title: 'Carrier Profile Onboarding',
      bodyClass: 'legacy-form',
    },
  },
  {
    path: '/configuration/carrier/:location/:carrierProfileReference/integration/:type',
    name: 'ConfigurationCarrierProfileIntegrationSingle',
    props: true,
    component: () => import('../pages/configuration/CarrierIntegration.vue'),
    meta: {
      title: 'Carrier Profile Configuration: Integration',
      bodyClass: 'legacy-form',
    },
  },
];

export default CarrierConfiguration;
