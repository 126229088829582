import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

const Tokens: Array<RouteRecordRaw> = [
  // Token list
  {
    path: '/tokens/',
    name: 'Tokens',
    component: () => import('../pages/tokens/TokensIndex.vue'),
    props: true,
    meta: {
      title: 'Tokens overview',
      bodyClass: 'legacy-form',
      access: {
        url: 'tokens',
        method: 'GET',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: '/tokens/create',
    name: 'TokenCreate',
    component: () => import('../pages/tokens/TokenCreate.vue'),
    props: true,
    meta: {
      title: 'Set up a new token',
      bodyClass: 'legacy-form',
      access: {
        url: 'tokens',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: '/tokens/:hash',
    name: 'TokensDetail',
    component: () => import('../pages/tokens/TokenDetail.vue'),
    props: true,
    meta: {
      title: 'Token',
      bodyClass: 'legacy-form',
      access: {
        url: 'tokens/:hash',
        method: 'POST',
        service: 'configs',
        version: 'v1',
      } as AccessMeta,
    },
  },
];

export default Tokens;
