import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/decision';

const Tracking: Array<RouteRecordRaw> = [
  {
    path: '/consignments',
    name: 'Consignments View',
    component: () => import('../pages/consignments/ConsignmentOverview.vue'),
    props: true,
    meta: {
      title: 'Consignment',
      bodyClass: 'legacy-form',
      betaMode: true,
    },
  },
  {
    path: '/test/consignments',
    name: 'Consignments View (Playground)',
    component: () => import('../pages/consignments/ConsignmentOverview.vue'),
    props: true,
    meta: {
      title: 'Consignment Playground',
      testMode: true,
      bodyClass: 'legacy-form',
      betaMode: true,
    },
  },
  {
    path: '/consignments/create',
    name: 'Consignment Create',
    component: () => import('../pages/consignments/ConsignmentCreate.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: 'Consignment Create',
      betaMode: true,
    },
  },
  {
    path: '/test/consignments/create',
    name: 'Consignment Create (playground)',
    component: () => import('../pages/consignments/ConsignmentCreate.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: 'Consignment Create',
      testMode: true,
      betaMode: true,
    },
  },
  {
    path: '/consignments/:id/edit',
    name: 'Edit Consignment',
    component: () => import('../pages/consignments/ConsignmentCreate.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: 'Edit Consignment',
      access: {
        url: 'consignments/:id/edit',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
  {
    path: '/consignments/:id',
    name: 'Consignments Page',
    component: () => import('../pages/consignments/ConsignmentPage.vue'),
    props: true,
    meta: {
      title: 'Consignments Page',
      betaMode: true,
      access: {
        url: 'consignments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: '/test/consignments/:id',
    name: 'Consignments Page (playground)',
    component: () => import('../pages/consignments/ConsignmentPage.vue'),
    props: true,
    meta: {
      title: 'Consignments Page',
      betaMode: true,
      testMode: true,
      access: {
        url: 'consignments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v1',
      } as AccessMeta,
    },
  },
  {
    path: '/consignments/tracking',
    name: 'trackingSignedUrl',
    component: () => import('../pages/tracking/3m-tracking.vue'),
    props: (route) => ({ signedUrl: route.query.signedUrl as string }),
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
      allowAnonymous: true,
    },
  },
  {
    path: '/consignments/tracking/:id',
    name: 'tracking',
    component: () => import('../pages/tracking/3m-tracking.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
      mode: 'production',
    },
  },
  {
    path: '/consignments/tracking/acceptance/:id',
    name: 'trackingAcceptance',
    component: () => import('../pages/tracking/3m-tracking.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
      mode: 'acceptance',
    },
  },
  {
    path: '/consignments/url-signer',
    name: 'trackingConfiguration',
    component: () => import('../pages/tracking/3m-tracking-overview.vue'),
    props: true,
    meta: {
      title: 'Tracking',
      bodyClass: 'legacy-form',
    },
  },
];

export default Tracking;
