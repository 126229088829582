import { type RouteRecordRaw } from 'vue-router';

const AddressV2Routes: Array<RouteRecordRaw> = [
  {
    path: '/address/',
    name: 'Addres List',
    component: () => import('../pages/address/AddressOverview.vue'),
    meta: {
      title: 'Address',
    },
  },
  {
    path: '/address/:id',
    name: 'Addres Detail',
    component: () => import('../pages/address/AddressDetail.vue'),
    props: {
      mode: 'edit',
    },
    meta: {
      title: 'Edit Address',
    },
  },
  {
    path: '/address/create',
    name: 'Address Create',
    component: () => import('../pages/address/AddressDetail.vue'),
    props: {
      mode: 'create',
    },
    meta: {
      title: 'Create Address',
    },
  },
];
export default AddressV2Routes;
