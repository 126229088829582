<template>
  <BaseDropDownMenu>
    <template #trigger>
      <IconComponent name="PhUserCircle" :data-testid="baseTestId + '.user.trigger'" />
    </template>
    <template #content>
      <RouterLink to="/user/profile" :data-testid="baseTestId + '.user.profile'">
        <DropdownMenuItem>
          <IconComponent name="PhUserCircle" class="mr-2" />
          <span>Profile</span>
        </DropdownMenuItem>
      </RouterLink>
      <a :href="logoutUrl" class="logout" :data-testid="baseTestId + '.user.logout'">
        <DropdownMenuItem>
          <IconComponent name="PhSignOut" class="mr-2" />
          <span>Logout</span>
        </DropdownMenuItem>
      </a>
    </template>
  </BaseDropDownMenu>
</template>
<script setup lang="ts">
import { Configuration, FrontendApi } from '@ory/client';
import { IconComponent } from '@shipitsmarter/viya-ui-warehouse';
import { onMounted, Ref, ref } from 'vue';

import { DropdownMenuItem } from '@/components/ui/dropdown-menu';
import BaseDropDownMenu from '@/components/utilities/BaseDropDownMenu.vue';

const baseTestId = 'nav-bar';
const logoutUrl: Ref<string> = ref('');

const ory = new FrontendApi(
  new Configuration({
    basePath: '/auth/ory',
    baseOptions: {
      withCredentials: true,
    },
  })
);

async function setLogoutUrl() {
  if (import.meta.env.PROD) {
    ory.createBrowserLogoutFlow().then(({ data }) => {
      logoutUrl.value =
        data.logout_url + '&return_to=' + encodeURIComponent(window.location.origin);
    });
  }
}

onMounted(async () => {
  await setLogoutUrl();
});
</script>
<style scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
